.postContainer img {
    width: 70%;
    text-align: center;
    margin: 0 15%;
}

.postContainer a {
    text-decoration: underline;
    color: #007bff;
}

.postContainer a:hover {
    color: #007bff;
}

.postFooter a {
    text-decoration: none;
    cursor: pointer;
}

a:visited {
    text-decoration: none;
    color: inherit
}

a:hover {
    text-decoration: none;
    color: inherit
}

a:focus {
    text-decoration: none;
    color: inherit;
}

a:hover,
a:active {
    text-decoration: none;
    color: inherit
}

.postFooter {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
}

.postContainer h1:first-of-type {
    margin-top: -10px
}

.postContainer h1, h2 {
    margin: 5px 0;
}